import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of test week`}</em></p>
    <p>{`Deadlift 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`CrossFit Open WOD 14.3`}</strong></p>
    <p>{`8:00 amrap of:`}</p>
    <p>{`10-Deadlifts (135/95, Masters over 55= 95/65)`}</p>
    <p>{`15-Box Jumps (24/20, Masters over 55= 20″)`}</p>
    <p>{`15-Deadlifts (185/135, 135/95)`}</p>
    <p>{`15-Box Jumps`}</p>
    <p>{`20-Deadlifts (225/155, 185/115)`}</p>
    <p>{`15-Box Jumps`}</p>
    <p>{`25-Deadlifts (275/185, 225/135)`}</p>
    <p>{`15-Box Jumps`}</p>
    <p>{`30-Deadlifts (315/205, 275/155)`}</p>
    <p>{`15-Box Jumps`}</p>
    <p>{`35-Deadlifts (365/225, 315/185)`}</p>
    <p>{`15-Box Jumps`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Bonus Test`}{`*`}{` `}</em></strong></p>
    <p>{`1 attemp, max unbroken ring muscle ups.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 22nd.  Sign up now at
Games.CrossFit.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start February 24th.  For more info
email Eric at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      